import { Flex, Image } from "@mantine/core";
import React from "react";
import { isIframe } from "src/lib/utils/widget";
import * as Styles from "./styles";

export const Logo = () => {
  const [logoURL, setLogoURL] = React.useState("");

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const url = !isIframe()
        ? "https://nerval.org"
        : window.location.href.replace("widget", "editor");

      setLogoURL(url);
    }
  }, []);

  if (!logoURL) return null;

  return (
    <Styles.StyledToolElement title="Nerval ML">
      <Flex gap="xs" align="center" justify="center" style={{ height: "100%" }}>
        <a
          href={logoURL}
          target={isIframe() ? "_blank" : "_parent"}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Image
            src="/assets/Nerval-Logo.png"
            alt="Nerval ML Logo"
            width={32}
            height={32}
            fit="contain"
          />
        </a>
      </Flex>
    </Styles.StyledToolElement>
  );
};
