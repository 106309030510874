import { Stack, Flex, Badge, Button, Group } from "@mantine/core";
import styled from "styled-components";
import Link from "next/link";
import React from "react";
import { FaBullhorn } from "react-icons/fa";
import { MdChevronRight } from "react-icons/md";

const StyledHeroTitle = styled.h1`
  position: relative;
  font-family: "Sohne", sans-serif;
  font-weight: 400;
  display: inline;
  color: #eeeeee;
  width: fit-content;
  line-height: 1.2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @media only screen and (max-width: 767px) {
    font-size: 2rem;
  }

  @media only screen and (min-width: 768px) {
    font-size: 3.2rem;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 4rem;
  }

  @media only screen and (min-width: 1440px) {
    max-width: 75%;
    font-size: 4.5rem;
  }

  /* Add this media query for the 500-600px range */
  @media only screen and (min-width: 500px) and (max-width: 600px) {
    font-size: 2.5rem;
  }
`;

const StyledHeroText = styled.p`
  font-family: "Sohne", sans-serif;
  font-size: 1rem;
  color: #ffffff;
  font-weight: 500;
  max-width: 100%;
  min-width: 400px;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  @media only screen and (min-width: 576px) {
    font-size: 1.2rem;
    max-width: 80%;
  }

  @media only screen and (min-width: 1400px) {
    font-size: 1.25rem;
    max-width: 60%;
  }
`;

const StyledHeroSection = styled.section`
  position: relative;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3E%3Cg mask='url(%26quot%3B%23SvgjsMask1119%26quot%3B)' fill='none'%3E%3Crect width='1440' height='560' x='0' y='0' fill='rgba(12, 53, 89, 1)'%3E%3C/rect%3E%3Cpath d='M0,516.667C97.207,524.663,191.544,478.044,269.854,419.901C345.016,364.094,378.262,274.21,430.856,196.765C495.751,101.206,637.289,24.921,615.069,-88.434C592.482,-203.661,409.919,-199.88,335.328,-290.564C257.435,-385.263,292.867,-566.149,180.94,-616.225C73.046,-664.497,-39.455,-551.784,-148.934,-507.224C-246.494,-467.515,-369.363,-457.951,-426.046,-369.171C-482.251,-281.139,-416.258,-165.35,-433.028,-62.26C-450.279,43.786,-567.755,141.196,-525.11,239.81C-482.675,337.94,-337.329,327.875,-242.757,377.737C-158.579,422.119,-94.841,508.866,0,516.667' fill='%2308243d'%3E%3C/path%3E%3Cpath d='M1440 1135.7820000000002C1545.808 1123.141 1636.772 1062.3980000000001 1720.561 996.562 1798.1109999999999 935.6279999999999 1857.255 858.789 1903.435 771.644 1952.04 679.923 1984.068 583.645 1994.123 480.329 2006.621 351.911 2035.068 212.54899999999998 1968.613 101.95400000000001 1899.453-13.143000000000029 1771.863-110.548 1637.608-112.99000000000001 1504.201-115.41600000000005 1422.116 31.84400000000005 1301.945 89.82900000000001 1207.994 135.16199999999998 1090.6689999999999 120.68599999999998 1011.807 188.969 927.791 261.715 883.552 368.377 855.922 476.022 825.905 592.967 799.091 719.988 847.068 830.783 895.329 942.2339999999999 1004.7719999999999 1013.623 1112.772 1069.1770000000001 1213.8029999999999 1121.147 1327.188 1149.26 1440 1135.7820000000002' fill='%23104676'%3E%3C/path%3E%3C/g%3E%3Cdefs%3E%3Cmask id='SvgjsMask1119'%3E%3Crect width='1440' height='560' fill='%23ffffff'%3E%3C/rect%3E%3C/mask%3E%3C/defs%3E%3C/svg%3E");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #121212;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(18, 18, 18, 0.7) 0%,
      rgba(18, 18, 18, 0.5) 50%,
      rgba(18, 18, 18, 0.7) 100%
    );
    pointer-events: none;
  }

  @media only screen and (max-width: 1240px) {
    flex-direction: column;
  }
`;

const StyledHeroSectionBody = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 4em 10%;
  overflow: hidden;
  backdrop-filter: blur(1.2px);
  -webkit-backdrop-filter: blur(1.2px);
  text-align: center;
  gap: 60px;
  min-height: 60vh;

  @media only screen and (max-width: 768px) {
    padding: 3em 16px;
  }
`;

const StyledBetaPill = styled(Link)`
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, #3a7bc8, #4a90e2);
  color: white;
  font-weight: 300;
  border-radius: 20px;
  font-size: 1rem;
  letter-spacing: 0.25px;
  padding: 6px 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  transition: all 0.3s ease;
  text-decoration: none;
  user-select: none;
  cursor: pointer;

  svg {
    margin-right: 6px;
    opacity: 0.9;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

export const HeroSection = () => {
  return (
    <StyledHeroSection id="hero-section">
      <StyledHeroSectionBody>
        <Stack flex="1" miw={250} mx="auto" align="center">
          <StyledBetaPill href="/">
            <FaBullhorn />
            Available Soon in Beta!
          </StyledBetaPill>
          <StyledHeroTitle>
            Leverage your enterprise data from a centralized source of truth.
          </StyledHeroTitle>
          <StyledHeroText>
            Nerval is the enterprise data pipeline built for the future.
          </StyledHeroText>
          <Flex gap="xs" py="md">
            <Badge size="xl" color="gray.7" autoContrast radius="sm" variant="light">
              AZURE BLOB
            </Badge>
            <Badge size="xl" color="gray.7" autoContrast radius="sm" variant="light">
              MICROSOFT 365
            </Badge>
            <Badge size="xl" color="gray.7" autoContrast radius="sm" variant="light">
              NOTION
            </Badge>
            <Badge size="xl" color="gray.7" autoContrast radius="sm" variant="light">
              ATLASSIAN
            </Badge>
            <Badge size="xl" color="gray.7" autoContrast radius="sm" variant="light">
              GOOGLE WORKSPACE
            </Badge>
            <Badge size="xl" color="gray.7" autoContrast radius="sm" variant="light">
              POSTGRES
            </Badge>
            <Badge size="xl" color="gray.7" autoContrast radius="sm" variant="light">
              MONGODB
            </Badge>
          </Flex>
          <Group>
            <Button
              component="a"
              variant="outline"
              color="blue"
              href="/learn"
              size="lg"
              radius="lg"
              style={{ color: "#eeeeee" }}
            >
              Learn More
            </Button>
            <Button
              component="a"
              variant="filled"
              color="blue"
              style={{ border: "1px solid #625BF6" }}
              href="/dashboard"
              size="lg"
              radius="lg"
              rightSection={<MdChevronRight size={30} />}
            >
              Get Started
            </Button>
          </Group>
        </Stack>
      </StyledHeroSectionBody>
    </StyledHeroSection>
  );
};