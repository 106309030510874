import { Text, Title, Grid, Paper, Badge, Group, PaperProps } from "@mantine/core";
import styled from "styled-components";
import { IconGraph, IconCode, IconSearch, IconFileTypography } from "@tabler/icons-react";
import React, { useCallback } from "react";
import Image from "next/image";

const StyledPaper = styled.div`
  position: relative;
  z-index: 1;

  &:before {
    position: absolute;
    z-index: -1;
    opacity: 0.1; /* Reduced opacity by 50% */
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-size: 20px 20px;
    background-image: linear-gradient(to right, #dcdcdc 1px, transparent 1px),
      linear-gradient(to bottom, #dcdcdc 1px, transparent 1px);
    background-position: top center;
    image-rendering: pixelated;
    -webkit-mask-image: linear-gradient(to bottom, transparent, 20%, white, 90%, transparent);
    mask-image: linear-gradient(to bottom, transparent, 20%, white, 90%, transparent);
  }
`;

interface StyledFeatureCardProps extends PaperProps {
  customHeight?: number;
  children: React.ReactNode;
}

const StyledFeatureCard = styled(Paper)<StyledFeatureCardProps>`
  position: relative;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  width: 100%;
  height: ${({ customHeight }) => customHeight ? `${customHeight}px` : "370px"};
  border: 1px solid #2c2e33;
  overflow: hidden;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    height: ${({ customHeight }) => customHeight ? `${customHeight - 70}px` : "300px"};
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`;

export const Features: React.FC = React.memo(() => {
  const handleEvent = useCallback(() => {
    // event handler logic
  }, []);

  return (
    <section id="features">
      <Title
        c="#eeeeee"
        order={2}
        px="lg"
        fz={{
          sm: 32,
          md: 42,
        }}
        fw={600}
        mt={120}
        mb={15}
        style={{ textAlign: "center" }}
      >
        Effortless data pipelines
      </Title>
      <Text
        c="gray.6"
        fz={{
          sm: 14,
          md: 16,
        }}
        px="lg"
        w={{
          sm: "80%",
          md: "60%",
        }}
        mx="auto"
        ta="center"
        mb={50}
      >
        Reimagine your data pipelines with Nerval. Effortless formatting, robust validation, and
        intuitive visualizations in one platform. Make smarter decisions faster.
      </Text>

      <Grid w="80%" gutter={24} mt={50} mb={150} mx="auto">
        <Grid.Col span={{ base: 12, sm: 6 }}>
          <StyledFeatureCard
            p={30}
            shadow="xs"
            radius="md"
            customHeight={370}
            withBorder
          >
            <div>
              <StyledPaper>
                <Group gap="xs">
                  <IconGraph size={28} color="#eeeeee" />
                  <Title c="#eeeeee" order={3} fw={500} fz={{ sm: 20, md: 28 }}>
                    Graphs
                  </Title>
                </Group>
                <Text fz={{ sm: 14, md: 18 }} c="gray.4" mt={10}>
                  Visualize your data in a graph format to understand and analyze it better.
                </Text>
                <Image
                  src="/assets/features/compare.webp"
                  alt="Compare"
                  width={400}
                  height={300}
                  quality={80}
                  style={{
                    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                  }}
                />
              </StyledPaper>
            </div>
          </StyledFeatureCard>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 6 }}>
          <StyledFeatureCard
            p={30}
            shadow="xs"
            radius="md"
            customHeight={370}
            withBorder
          >
            <div>
              <StyledPaper>
                <Group gap="xs">
                  <IconCode size={28} color="#eeeeee" />
                  <Title c="#eeeeee" order={3} fw={500} fz={{ sm: 20, md: 28 }}>
                    Generate Types
                  </Title>
                </Group>
                <Text fz={{ sm: 14, md: 18 }} c="gray.4" my={10}>
                  Generate types for your data with a single click: TypeScript, Go, Rust & more.
                </Text>
                <Paper
                  withBorder
                  shadow="sm"
                  radius={5}
                  w="fit-content"
                  mx="auto"
                  style={{ borderColor: "gray" }}
                >
                  <Image
                    loading="lazy"
                    src="./assets/features/edit.webp"
                    alt="Edit"
                    width={340}
                    height={200}
                    style={{ borderRadius: '4px' }}
                  />
                </Paper>
              </StyledPaper>
            </div>
          </StyledFeatureCard>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 6 }}>
          <StyledFeatureCard
            p={30}
            shadow="xs"
            radius="md"
            customHeight={370}
            withBorder
          >
            <div>
              <StyledPaper>
                <Group gap="xs">
                  <IconSearch size={28} color="#eeeeee" />
                  <Title c="#eeeeee" order={3} fw={500} fz={{ sm: 20, md: 28 }}>
                    Search
                  </Title>
                </Group>
                <Text fz={{ sm: 14, md: 18 }} c="gray.4" mt={10}>
                  Highlight and search what you need in your data, without any hassle.
                </Text>
                <Image
                  loading="lazy"
                  src="./assets/features/search.webp"
                  alt="Search"
                  width={400}
                  height={500}
                  style={{
                    margin: 'auto',
                    marginTop: 20,
                    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                  }}
                />
              </StyledPaper>
            </div>
          </StyledFeatureCard>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 6 }}>
          <StyledFeatureCard
            p={30}
            shadow="xs"
            radius="md"
            customHeight={370}
            withBorder
          >
            <div>
              <StyledPaper>
                <Group gap="xs">
                  <IconFileTypography size={28} color="#eeeeee" />
                  <Title c="#eeeeee" order={3} fw={500} fz={{ sm: 20, md: 28 }}>
                    Choose Your Format
                  </Title>
                </Group>
                <Text fz={{ sm: 14, md: 18 }} c="gray.4" mt={10}>
                  Visualize and edit your data in multiple formats. JSON, YAML, CSV, XML, and TOML are
                  supported.
                </Text>
                <Grid gutter="lg" mt={50}>
                  <Grid.Col span={6}>
                    <Badge
                      w="100%"
                      mih={{ sm: 10, md: 40 }}
                      variant="light"
                      c="indigo"
                      color="indigo"
                      radius="sm"
                      size="xl"
                    >
                      JSON
                    </Badge>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Badge
                      w="100%"
                      mih={{ sm: 10, md: 40 }}
                      variant="light"
                      color="cyan"
                      radius="sm"
                      c="cyan"
                      size="xl"
                    >
                      YAML
                    </Badge>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Badge
                      w="100%"
                      mih={{ sm: 10, md: 40 }}
                      variant="light"
                      color="grape"
                      radius="sm"
                      c="grape"
                      size="xl"
                    >
                      CSV
                    </Badge>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Badge
                      w="100%"
                      mih={{ sm: 10, md: 40 }}
                      variant="light"
                      color="red"
                      radius="sm"
                      size="xl"
                      c="red"
                    >
                      XML
                    </Badge>
                  </Grid.Col>
                </Grid>
              </StyledPaper>
            </div>
          </StyledFeatureCard>
        </Grid.Col>
      </Grid>
    </section>
  );
});

Features.displayName = "Features";