import styled from "styled-components";
import localFont from "next/font/local";
import Link from "next/link";
import React from "react";
import { isIframe } from "src/lib/utils/widget";

const sohneFett = localFont({
  src: "../assets/fonts/Sohne-Fett.woff2",
  
  variable: "--sohne-fett",
  display: "swap",
  fallback: ["Futura, Helvetica, sans-serif", "Tahoma, Verdana, sans-serif"],
});

const sohneLeicht = localFont({
  src: "../assets/fonts/Sohne-Leicht.woff2",
  variable: "--sohne-leicht",
  display: "swap",
  fallback: ["Futura, Helvetica, sans-serif", "Tahoma, Verdana, sans-serif"],
});

const StyledTitle = styled.div<{ fontSize: string }>`
  font-weight: 800;
  margin: 0;
  font-size: ${({ fontSize }) => fontSize};
  white-space: nowrap;
  z-index: 10;
  color: ${({ theme }) => theme.INTERACTIVE_HOVER};
  vertical-align: middle;
`;

const StyledNerval = styled.span`
  font-family: ${sohneLeicht.style.fontFamily};
`;

const StyledML = styled.span`
  font-family: ${sohneFett.style.fontFamily};
`;

interface LogoProps extends React.ComponentPropsWithoutRef<"a"> {
  fontSize?: string;
}

export const JSONCrackLogo: React.FC<LogoProps> = ({ fontSize = "1.2rem", ...props }) => {
  const logoText = React.useMemo(() => {
    if (typeof window === "undefined") return "Nerval ML";
    return isIframe() ? "ML" : "Nerval ML";
  }, []);

  return (
    <StyledTitle as={Link} fontSize={fontSize} href="/" prefetch={false} {...props}>
      {logoText === "ML" ? (
        <StyledML>{logoText}</StyledML>
      ) : (
        <>
          <StyledNerval>Nerval</StyledNerval> <StyledML>ML</StyledML>
        </>
      )}
    </StyledTitle>
  );
};
